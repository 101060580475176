<!-- 标签库-编辑 -->
<template>
    <div class="labelIndex">
        <h4>模型列表</h4>
        <div style="font-size: 14px;">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="未关联构件" name="second">
                    <AssociatingNewArtifacts ref="refTwo"></AssociatingNewArtifacts>
                </el-tab-pane>
                <el-tab-pane label="已关联构件" name="first">
                    <AssociatedArtifacts ref="refOne"></AssociatedArtifacts>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import AssociatedArtifacts from './components/associatedArtifacts.vue';
import AssociatingNewArtifacts from './components/associatingNewArtifacts.vue';
export default {
    // 组件名称
    name: 'labelIndex',
    // 局部注册的组件
    components: {
        AssociatedArtifacts,
        AssociatingNewArtifacts,
    },
    // 组件状态值
    data() {
        return {
            // 默认选择
            activeName: 'second',
        }
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    methods: {
        handleClick(tab) {
            if (tab.props.name == 'first') {
                this.$refs.refOne.init()
            }
            if (tab.props.name == 'second') {
                this.$refs.refTwo.init()
            }
        },
    },
    created() {
    },
}
</script>

<style lang='scss' scoped>
.labelIndex {
    padding: 0 20px 20px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0);
}
</style>
